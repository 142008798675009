<template>
  <div class="wrapper-circle" v-if="circle">
    <v-progress-circular indeterminate :width="10" :size="70" />
  </div>
  <div class="wrapper-loader" v-else>Загрузка</div>
</template>
<script>
export default {
  props: {
    circle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.wrapper-circle {
  padding: 20px;
  backdrop-filter: blur(10px);
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: 3;
  border-radius: 10px;
  display: flex;

  justify-content: center;
  align-items: center;
  color: #2757ff;
}

.wrapper-loader {
  backdrop-filter: blur(10px);
  background-color: rgba(26, 41, 91, 0.3);
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: 3;
  border-radius: 10px;
  display: flex;

  justify-content: center;
  align-items: center;
  color: white;
  font-size: 50px;

  &::after {
    display: inline-block;
    animation: dotty steps(1, end) 1s infinite;
    content: "";
  }

  @keyframes dotty {
    0% {
      content: "";
    }
    25% {
      content: ".";
    }
    50% {
      content: "..";
    }
    75% {
      content: "...";
    }
    100% {
      content: "";
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 40px;
    }
  }
}
</style>
